import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    name: "",
    pageNo: "",
    per_page: "",
    vendorFilterValue: {
        searchVal: "",
        cities: [],
        cityLoading: false,
        displayStateValue: [],
        displayCityValue: [],
        currentStateRule: [],
        currentCityRule: [],

        services: [],
        servicesLoading: false,
        displayTeamsValue: [],
        displayServicesValue: [],
        currentTeamsRule: [],
        currentServicesRule: [],

        //actions
        status: "all",
    },
    formValue: {
        name: "",
        city: "",
        states: "",
        gstin: "",
        spoc: "",
        mobile: "",
        category_id: [],
    },
    displayStateValue: [],
    displayCityValue: [],
    currentStateRule: [],
    currentCityRule: [],
    mulitpleSelected: [],
}

const VendorSlice = createSlice({
    name: "vendors",
    initialState,
    reducers: {
        setFormValue: (state: any, action) => {
            state.formValue = action?.payload
        },
        setSearchValue: (state: any, action) => {
            state.searchVal = action?.payload
        },
        setStateDisplayValue: (state: any, action) => {
            state.displayStateValue = action?.payload
        },
        setCityDisplayValue: (state: any, action) => {
            state.displayCityValue = action?.payload
        },
        setCurrentStateRules: (state: any, action) => {
            state.currentStateRule = action?.payload
        },
        setCurrentCityRules: (state: any, action) => {
            state.currentCityRule = action?.payload
        },
        setFilterStateDisplayValue: (state: any, action) => {
            state.vendorFilterValue.displayStateValue = action?.payload
        },
        setFilterCityDisplayValue: (state: any, action) => {
            state.vendorFilterValue.displayCityValue = action?.payload
        },
        setFilterCurrentStateRules: (state: any, action) => {
            state.vendorFilterValue.currentStateRule = action?.payload
        },
        setFilterCurrentCityRules: (state: any, action) => {
            state.vendorFilterValue.currentCityRule = action?.payload
        },
        setFilterTeamsDisplayValue: (state: any, action) => {
            state.vendorFilterValue.displayTeamsValue = action?.payload
        },
        setFilterServicesDisplayValue: (state: any, action) => {
            state.vendorFilterValue.displayServicesValue = action?.payload
        },
        setFilterCurrentTeamsRules: (state: any, action) => {
            state.vendorFilterValue.currentTeamsRule = action?.payload
        },
        setFilterCurrentServicesRules: (state: any, action) => {
            state.vendorFilterValue.currentServicesRule = action?.payload
        },
        setStatus: (state: any, action) => {
            state.vendorFilterValue.status = action?.payload
        },
        resetVendorFilter: (state: any) => {
            state.vendorFilterValue = initialState.vendorFilterValue
        },
    },
})

export const {
    setFormValue,
    setSearchValue,
    setFilterTeamsDisplayValue,
    setFilterServicesDisplayValue,
    setFilterCurrentServicesRules,
    setFilterCurrentTeamsRules,
    setFilterStateDisplayValue,
    setFilterCityDisplayValue,
    setFilterCurrentCityRules,
    setFilterCurrentStateRules,
    setStateDisplayValue,
    setCityDisplayValue,
    setCurrentCityRules,
    setCurrentStateRules,
    setStatus,
    resetVendorFilter,
} = VendorSlice.actions

export default VendorSlice.reducer
