import { api } from "../../api"
import { setAlertFunc } from "../../components/AlertToaster/AlertSlice"
import {
    HistoryResponse,
    IResponse,
    StatusButton,
    TabsResponseType,
    TicketDataResponseType,
} from "../Common/commonTypes"

const MODULE_URL = `onboarding/cpo`

const CPOService = api.injectEndpoints({
    endpoints: build => ({
        getCPOTabs: build.query({
            query: params => ({
                url: `${MODULE_URL}/total`,
                params: {
                    'state_id': JSON.stringify(params.data.stateId ?? []),
                    'status_ids': JSON.stringify(params.data.statusId ?? []),
                    "city_id": JSON.stringify(params.data.cityId ?? []),
                    'assigned_to': params.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(params.data.age ?? []),
                    'age_op': params.data.age_op ?? '',
                    'raised_on_end': params.data.TicketEndDate ?? '',
                    'raised_on_start': params.data.TicketStartDate ?? '',
                    'charger_type': JSON.stringify(params.data.chargerIds ?? []),
                    'read_filter': params.data.ReadUnread ?? 'all',
                    'search_key': params.data.search_key ?? '',
                    'per_page': params?.selectedValue,
                    'oem_id': params.data.oemId?.id ?? '',
                    'page': params?.page
                },
            }),
            transformResponse: (response: TabsResponseType) => response.data.message,
            providesTags: ['CPO'],
        }),
        getCPOData: build.query<TicketDataResponseType, { data: any; selectedValue: number; page: number }>({
            query: params => ({
                url: `${MODULE_URL}/ticket/${params.data.parent_status ?? "all_tab"}`,
                params: {
                    'state_id': JSON.stringify(params.data.stateId ?? []),
                    'status_ids': JSON.stringify(params.data.statusId ?? []),
                    "city_id": JSON.stringify(params.data.cityId ?? []),
                    'assigned_to': params.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(params.data.age ?? []),
                    'age_op': params.data.age_op ?? '',
                    'raised_on_end': params.data.TicketEndDate ?? '',
                    'raised_on_start': params.data.TicketStartDate ?? '',
                    'charger_type': JSON.stringify(params.data.chargerIds ?? []),
                    'read_filter': params.data.ReadUnread ?? 'all',
                    'search_key': params.data.search_key ?? '',
                    'per_page': params?.selectedValue,
                    'oem_id': params.data.oemId?.id ?? '',
                    'page': params?.page
                },
            }),
            providesTags: ['CPO'],
        }),
        getCPOTicketDetails: build.query<IResponse<any>, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/ticket_details`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: ['CPO'],
        }),
        getCPOTicketHistory: build.query<HistoryResponse, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/history`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, args) => [{ type: "CPO", id: args.ticket_id }],
        }),
        getCPOButtonDropdown: build.query<IResponse<StatusButton[]>, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/button`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, args) => [{ type: "CPO", id: args.ticket_id }],
        }),
        getCPOSkipStatuses: build.query<IResponse<{ id: number; name: string }>,{ search_key: string } | void>({
        query: params => ({
            url: `${MODULE_URL}/statuses`,
            params: {
                search_key: params?.search_key ?? "",
            },
        }),
        providesTags: ["CPO"],
    }),
        editCPOTicketStatus: build.mutation<IResponse<string[]>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ticket`,
                method: "PUT",
                body: body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["CPO"],
        }),
        editCPOTicketDetail: build.mutation<IResponse<string>, any>({
            query: body => ({
                url: `${MODULE_URL}/ticket_details`,
                method: "PUT",
                body: body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["CPO"],
        }),
        addCPOAdmin: build.mutation<IResponse<[string]>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ticket_details`,
                method: "POST",
                body: body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.data}))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["CPO"],
        }),
    }),
})

export const {
    useLazyGetCPOTabsQuery,
    useLazyGetCPODataQuery,
    useGetCPOButtonDropdownQuery,
    useGetCPOTicketDetailsQuery,
    useGetCPOTicketHistoryQuery,
    useLazyGetCPOSkipStatusesQuery,
    useEditCPOTicketDetailMutation,
    useEditCPOTicketStatusMutation,
    useAddCPOAdminMutation
} = CPOService

export { CPOService }