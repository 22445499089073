import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"
import { TStore } from "../store/Store"

const rawBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        const store = getState() as TStore
        const { isAuth, user_id } = store.verifyLogin
        const token = localStorage.getItem("access_token")
        if (isAuth && user_id && token) {
            headers.set("Auth-Token", token)
        }
        return headers
    },
    paramsSerializer: (params: Record<string, any>) => {
        return Object.entries(Object.assign({}, params))
            .map(([key, value]) => `${key}=${value}`)
            .join("&")
    },
})

let refreshTokenInProgress = false;
let pendingRequests: (() => void)[] = [];

const customBaseQuery: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const store = api.getState() as TStore
    const { user_id } = store.verifyLogin

    let modifiedArgs: FetchArgs = { ...args }

    if (args.method === "GET" || api.type === "query" || args.method === "DELETE") {
        modifiedArgs = {
            ...args,
            params: { ...args.params, user_id },
        }
    }

    if (api.type === "mutation" && args.method !== "DELETE") {
        if (args.body instanceof FormData) {
            const newFormData = new FormData()
            args.body.forEach((value, key) => newFormData.append(key, value))
            newFormData.append("user_id", user_id)
            modifiedArgs.body = newFormData
        } else
            modifiedArgs.body = {
                ...args.body,
                user_id,
            }
    }

    let result = await rawBaseQuery(modifiedArgs, api, extraOptions)

    // Call refresh token if the error code is 401
    if (result.error && result.error.status === 401) {
        if (!refreshTokenInProgress) {
            refreshTokenInProgress = true

            const refreshToken = localStorage.getItem("refresh_token")
            if (refreshToken) {
                const fetchArgs: FetchArgs = {
                    url: `/jwt/generate-new-tokens`,
                    headers: {
                        "Refresh-Token": refreshToken,
                    },
                    params: {
                        user_id,
                    },
                }

                const refreshTokenResult = await rawBaseQuery(fetchArgs, api, extraOptions)
                if (refreshTokenResult?.data) {
                    let tokenObj = refreshTokenResult?.data as { token: { refresh_token: string; access_token: string } }
                    localStorage.setItem("refresh_token", tokenObj.token.refresh_token)
                    localStorage.setItem("access_token", tokenObj.token.access_token)

                    // Retry the original request
                    result = await customBaseQuery(modifiedArgs, api, extraOptions) as any
                    // Execute pending requests
                    pendingRequests.forEach(callback => callback())
                    pendingRequests = []
                } else {
                    localStorage.clear()
                    window.location.replace("/auth")
                }
            } else {
                localStorage.clear()
                window.location.replace("/auth")
            }

            refreshTokenInProgress = false
        } else {
            // If a refresh is already in progress, queue this request
            return new Promise((resolve) => {
                pendingRequests.push(async () => {
                    const result = await customBaseQuery(modifiedArgs, api, extraOptions)
                    resolve(result)
                })
            }) as any
        }
    }

    return result
}

export const api = createApi({
    baseQuery: customBaseQuery,
    endpoints: () => ({}),
    tagTypes: [
        "Accounts",
        "Projects",
        "Installation",
        "EvApplication",
        "Survey",
        "Logistics",
        "Finance",
        "Stations",
        "CPO",
        "Host",
        "Investor",
        "ThirdParty",
        "Distributors",
        "Transporters",
        "OpVendors",
        "Admin",
        "Settings",
        "TechSupport",
        "CustomerSupport",
        "Accounts"
    ],
})
