import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { api } from "../../api"
import { TStore } from "../../store/Store"
import { getTagType } from "../../utils/utils"
import { CategoryObj, ChargerDetailsData, ChargerTypeObj, CPOResponseType, IResponse, TabsResponseType, TagTypes, UserInfo } from "./commonTypes"

const commonService = api.injectEndpoints({
    endpoints: build => ({
        getStates: build.query<{ id: number; name: string }[], void>({
            query: () => ({
                url: `states`,
            }),
        }),
        getCities: build.query<{ id: number; name: string }[], { state_id: number[] }>({
            query: ({ state_id }) => ({
                url: `cities`,
                params: {
                    state_id: state_id.join(","),
                },
            }),
        }),
        // Query to get charger details associated with a ticket
        getChargerDetails: build.query<ChargerDetailsData[], { ticket_id: string; ticket_type: string }>({
            query: params => ({
                url: `charger_details`,
                params: {
                    ticket_id: params.ticket_id,
                    ticket_type: params.ticket_type,
                },
            }),
            transformResponse: (response: IResponse<ChargerDetailsData[]>) => response.data,
            providesTags: (result, error, arg) => [
                { type: getTagType(arg.ticket_type) as TagTypes, id: arg.ticket_id },
            ],
        }),
        // Query to get all charger types available
        getChargersType: build.query<ChargerTypeObj[], void>({
            query: () => ({
                url: `chargers`,
            }),
        }),
        // Query to get all statuses of a particular module
        getAllStatus: build.query<{ id: number; name: string }[], { mod_id: number; sub_module: string }>({
            query: params => ({
                url: `childstatus`,
                params: params,
            }),
        }),
        // Query to fetch OEM filter options
        getOEM: build.query<IResponse<any>, { search_key?: string; category_id?: number | "" }>({
            query: () => ({
                url: `oem`
            }),
        }),
        // Query to fetch CPO filter options
        getCPO: build.query<CPOResponseType, { search_key?: string; category_id?: number | "" }>({
            query: () => ({
                url: `onboarding/host/listcpo`
            }),
        }),
        // Query to fetch assigned-to filter options
        getAssignedToFilterOptions: build.query<
            Partial<UserInfo[]>,
            { search_key: string; ticket_id: string; module_id: number; submodule_id: number | "" }
        >({
            queryFn: async (params, { getState }, extraOptions, baseQuery) => {
                const state = getState() as TStore
                const role_id = state?.verifyLogin?.loginData?.user_data?.role_id

                const response = await baseQuery({
                    url: `get_assign_to`,
                    params: {
                        ...params,
                        role_id: role_id ?? "",
                    },
                })

                if (response.error) {
                    return { error: response.error as FetchBaseQueryError }
                }

                return { data: response.data as Partial<UserInfo[]> }
            },
        }),
        // Query to fetch assigned-to list for progress component
        getAssignedToListOptions: build.query<
            Partial<UserInfo[]>,
            { search_key: string; ticket_id: string; module_id: number; submodule_id?: number }
        >({
            queryFn: async (params, { getState }, extraOptions, baseQuery) => {
                const state = getState() as TStore
                const role_id = state?.verifyLogin?.loginData?.user_data?.role_id

                const response = await baseQuery({
                    url: `comments/assign_to`,
                    params: {
                        ...params,
                        submodule_id: params.submodule_id ?? "",
                        role_id: role_id ?? "",
                    },
                })

                if (response.error) {
                    return { error: response.error as FetchBaseQueryError }
                }

                return { data: response.data as Partial<UserInfo[]> }
            },
        }),
        getVendorList: build.query<{ id: number; name: string }[], { search_key?: string; category_id?: number | "" }>({
            query: ({ search_key = "", category_id = "" }) => ({
                url: `third_party/vendor_list`,
                params: {
                    search_key,
                    category_id,
                },
            }),
        }),
        getCategories: build.query<
            CategoryObj[],
            { module_id: number; is_visible?: boolean; team_id?: number; modules_flag?: boolean }
        >({
            query: params => ({
                url: `categories`,
                params: {
                    ...params,
                    is_visible: params?.is_visible ? "True" : params.module_id === 2 ? "False" : "True"
                },
            }),
        }),
        // Query to fetch charger SKUs and name, id
        getChargerSku: build.query<{ charger_type_id: number; name: string; sku: string }[], void>({
            query: () => ({
                url: `get_charger_skus`,
            }),
        }),
        // Query to fetch time of day
        getTimeOfDay: build.query<IResponse<{ id: number; name: string }[]>, void>({
            query: () => ({
                url: `time_of_day`,
            }),
        }),

        getDealDoneByUsers: build.query<{ id: number; name: string }[], void>({
            query: () => ({
                url: `operations/deal_done_by_users`
            })
        }),
        // Mutation to update charger details for a particular ticket
        updateChargerDetails: build.mutation<
            IResponse<string>,
            { data: { ticket_id: string; ticket_type: string; data: Partial<ChargerDetailsData[]> } }
        >({
            query: ({ data }) => ({
                url: `charger_details`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [
                getTagType(arg.data.ticket_type) as TagTypes,
                "Logistics"
            ],
        }),
    }),
})

export const {
    useLazyGetChargerDetailsQuery,
    useGetStatesQuery,
    useGetCitiesQuery,
    useGetChargersTypeQuery,
    useGetAllStatusQuery,
    useGetAssignedToFilterOptionsQuery,
    useGetAssignedToListOptionsQuery,
    useGetVendorListQuery,
    useGetCategoriesQuery,
    useGetChargerSkuQuery,
    useGetTimeOfDayQuery,
    useGetOEMQuery,
    useGetCPOQuery,
    useGetDealDoneByUsersQuery,
    useUpdateChargerDetailsMutation,
} = commonService
