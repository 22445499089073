import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    currentRole: [],
}

const RoleSlice = createSlice({
    name: "roles",
    initialState,
    reducers: {
        setCurrentRole(state, action) {
            state.currentRole = action.payload
        },
    },
})

export const { setCurrentRole } = RoleSlice.actions

export default RoleSlice.reducer
