import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setAlertFunc } from '../../components/AlertToaster/AlertSlice';
import { axiosInstance } from '../Auth/components/axiosInterceptors';
import { getDealDetails, getDemolition, getEVData, getEvApplicationDetails, getInstallation } from '../Operations/OperationSlice';
import { getInvestorData, getInvestorTicketDetails, getStationsData, getStationsTicketDetails } from '../Onboarding/OnboardingSlice';
import { getProjectsData } from '../Projects/ProjectSlice';
import { getNetworkReliabilityData, getTicketDetails as getNetworkReliabilityTicketDeatil } from '../NetworkReliability/NetworkReliabilitySlice';
import { customerSupportService } from '../CustomerSupport/CustomerSupportServices';
import { techSupportService } from '../TechSupport/TechSupportServices';
import { financeService } from '../Finance/FinanceServices';
import { surveyService } from '../Operations/features/Survey/SurveyService';
import { logisticsService } from '../Logistics/LogisticsServices';
import { CPOService } from '../Onboarding/CPOServices';
import { HostService } from '../Onboarding/HostServices';

const initialState = {
    categoriesLoading: false,
    statusLoading: false,
    chargersLoading: false,
    statesLoading: false,
    addSuccess: false,
    vendorListLoading: false,
    reasonLoading: false,
    categories: [],
    status: [],
    chargers: [],
    states: [],
    cities: [],
    destinationCitiesLoading:false,
    destinationCities:[],
    vendorList: [],
    reasons: [],
    StationData: [],
    StationLoading: false,
    dealDoneData: [],
    dealDoneByUsersLoading: false,
    currentModuleId: '',
    CommentMentionsLoading: false,
    CommentMentions: [],
    comments: [],
    getCommentsLoading: false,
    getCommentsSuccess: false,
    addCommentLoading: false,
    addCommentSuccess: false,
    editCommentLoading: false,
    editCommentSuccess: false,
    deleteCommentLoading: false,
    deleteCommentSuccess: false,
    AssignedToLoading: false,
    AssignedToData: [],
    AssignedToEditLoading: false,
    // 
    AssignedToFilterLoading: false,
    AssignedToFilterData: [],
    // 
    oemLoading: false,
    oemData: [],
    brandsLoading: false,
    brandsData: [],
    CPOLoading: false,
    CPOData: [],
    TimeLoading: false,
    TimeData: [] as any,
    DocumentTemplateLoading:false,
    DocumentTemplateData:[],
    chargerDetails: [],
    makeData:[],
    amenities:[],
    chargerDetailsLoading:false,
    updateChargerDeatilsSuccess:false,
    updateChargerDeatilsLoading :false
}

export const getCategories: any = createAsyncThunk(
    'categories', async (module_id: any, thunkApi: any) => {
        try {
            let moduleId, teamId,isVisible,modulesFlag;
            if (typeof module_id === 'object') {
                moduleId = module_id?.module_id;
                teamId = module_id?.team_id;
                isVisible = module_id?.is_visible;
                modulesFlag = module_id?.modules_flag;
            }
            else {
                moduleId = module_id;
            }
            const response: any = await axiosInstance(`categories`, {
                method: 'GET',
                params: {
                    "module_id": moduleId ?? '',
                    "is_visible": isVisible ? "True" : moduleId === 2 ? "False" : "True",
                    "team_id": teamId ?? '',
                    "modules_flag": modulesFlag ?? ''
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getStatuses: any = createAsyncThunk(
    'status', async (obj: any, thunkApi: any) => {
        let status_obj = {
            "sub_module": obj.sub_module ?? '',
            "mod_id": obj.mod_id ?? ''
        }
        let mod_obj = {
            "mod_id": obj.mod_id ?? ''
        }
        try {
            const response: any = await axiosInstance(`childstatus`, {
                method: 'GET',
                params:
                    obj.sub_module === '' || obj.sub_module === undefined ? mod_obj : status_obj
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getChargers: any = createAsyncThunk(
    'charger', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`chargers`, {
                method: 'GET',
                data: JSON.stringify(thunkApi)
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getOEM: any = createAsyncThunk(
    'getOEM', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`oem`, {
                method: 'GET',
                data: JSON.stringify(thunkApi)
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
});

export const getBrands: any = createAsyncThunk(
    'getBrands', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`branding`, {
                method: 'GET',
                data: JSON.stringify(thunkApi)
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
});
export const getCPO: any = createAsyncThunk(
    'getCPO', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/host/listcpo`, {
                method: 'GET',
                data: JSON.stringify(thunkApi)
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getTime: any = createAsyncThunk(
    'getTime', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`time_of_day`, {
                method: 'GET',
                data: JSON.stringify(thunkApi)
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getDocumentTemplate: any = createAsyncThunk(
    'getDocumentTemplate', async (category_id:any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`document_templates`, {
                method: 'GET',
                params:{
                    category_id: category_id ?? ''
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getStates: any = createAsyncThunk(
    'states', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`states`, {
                method: 'GET',
                data: JSON.stringify(thunkApi)
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getCities: any = createAsyncThunk(
    'cities', async (thunkApi: any) => {
        try {
            if (!thunkApi) return []
            const response: any = await axiosInstance(`cities`, {
                method: 'GET',
                params: {
                    "state_id": thunkApi ?? ''
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
    export const getDestinationCities: any = createAsyncThunk(
        'getDestinationCities', async (thunkApi: any) => {
            try {
                if (!thunkApi) return []
                const response: any = await axiosInstance(`cities`, {
                    method: 'GET',
                    params: {
                        "state_id": thunkApi ?? ''
                    }
                })
                return response.data;
            } catch (error: any) {
                return thunkApi.rejectWithValue({ error: error.message });
            }
        });


export const getVendorList: any = createAsyncThunk(
    'getVendorList', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`third_party/vendor_list`, {
                method: 'GET',
                params: {
                    "search_key": thunkApi.search_key ?? '',
                    "category_id": thunkApi.category_id ?? ''
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

    export const getReasons = createAsyncThunk(
        'getReasons', async (thunkAPI:any) => {
            try {
                const params = {
                    "submodule_id": thunkAPI?.submodule_id || "",
                    "module_id": thunkAPI?.module_id,
                }
                const response: any = await axiosInstance(`settings/reasons`, {
                    method: 'GET',
                    params
                })
                return await response.data;
            } catch (error: any) {
                return thunkAPI.rejectWithValue({ error: error.message });
            }
        })
export const getStationDetail: any = createAsyncThunk(
    'getStationDetail', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`station_details`, {
                method: 'GET',
                params: {
                    "ticket_id": obj.ticket_id ?? '',
                    "sub_module": obj.sub_module ?? ''
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getDealDoneByUsers: any = createAsyncThunk(
    'getDealDoneByUsers', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`operations/deal_done_by_users`, {
                method: 'GET',
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

/* Comments api */
export const getComments: any = createAsyncThunk(
    'comments', async (thunkApi: any, { getState, dispatch }) => {
        const params = {
            "ticket_id": thunkApi?.ticket_id ?? '',
            "submodule_id": thunkApi?.submodule_id || "",
            "module_id": thunkApi?.module_id,
        }

        try {
            const response: any = await axiosInstance(`comments/comment`, {
                method: 'GET',
                params
            })
            // response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: "Success" }))
            return response.data;
        } catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const addComments: any = createAsyncThunk(
    'addComments', async (thunkApi: any, { getState, dispatch }: any) => {
        const response: any = await axiosInstance(`comments/comment`, {
            method: 'POST',
            data: thunkApi.data
        }).then(
            (data: any) => {
                dispatch(getComments({ ticket_id: thunkApi.data.get("ticket_id"), submodule_id: thunkApi.data.get("submodule_id"), module_id: thunkApi.data.get("module_id") }))
                data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
                return data;
            }
        ).catch((error: any) => {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        })
        return response
    });

export const editComments: any = createAsyncThunk(
    'editComments', async (thunkApi: any, { getState, dispatch }) => {
        const response: any = await axiosInstance(`comments/comment`, {
            method: 'PUT',
            data: JSON.stringify(thunkApi.data)
        }).then(
            (data: any) => {
                dispatch(getComments({ ticket_id: thunkApi?.ticket_id, submodule_id: thunkApi.data?.submodule_id, module_id: thunkApi.data?.module_id }))
                data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
                return data
            }
        ).catch((error: any) => {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        })
        return response
    });

export const deleteComments: any = createAsyncThunk(
    'deleteComments', async (thunkApi: any, { getState, dispatch }) => {
        const response: any = await axiosInstance(`comments/comment?id=${thunkApi?.commentId}&ticket_id=${thunkApi?.ticket_id}&user_id=${thunkApi?.userId}&module_id=${thunkApi?.module_id}&submodule_id=${thunkApi?.submodule_id}`, {
            method: 'DELETE',
            // data: JSON.stringify(thunkApi)
        }).then(
            (data: any) => {
                dispatch(getComments({ ticket_id: thunkApi?.ticket_id, submodule_id: thunkApi?.submodule_id, module_id: thunkApi?.module_id }))
                data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
                return data
            }
        ).catch((error: any) => {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        })
        return response
    });

/* assign to */
export const getAssignedToList: any = createAsyncThunk(
    'get/assigned-to', async (thunkApi: any, { getState, dispatch }) => {        
        try {
            const currentState: any = getState();
            const response: any = await axiosInstance(`comments/assign_to`, {
                method: 'GET',
                params: {
                    "ticket_id": thunkApi.ticket_id ?? '',
                    "submodule_id": thunkApi.submodule_id ?? '',
                    "module_id": thunkApi.module_id ?? '',
                    "search_key": thunkApi.search_key ?? '',
                    "role_id": currentState?.verifyLogin?.loginData?.user_data?.role_id
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const editAssignedToList: any = createAsyncThunk(
    'editAssignedToList', async (thunkApi: any, { getState, dispatch }) => {

        try {
            const response: any = await axiosInstance(`comments/assign_to`, {
                method: 'PUT',
                data: JSON.stringify(thunkApi)
            })
            const currentState: any = getState();
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))

            if (response.status === 200) {
                thunkApi.module_id == 2 && thunkApi.submodule_id == 1 && (thunkApi.is_dealDetails === true ? dispatch(getDealDetails(thunkApi.ticket_id)) : dispatch(getInstallation({ data: currentState.operation.installationFormValue, page: thunkApi.page, selectedValue: thunkApi.per_page })))
                thunkApi.module_id == 2 && thunkApi.submodule_id == 2 &&  (thunkApi.is_dealDetails === true ? dispatch(getEvApplicationDetails(thunkApi.ticket_id)) :dispatch(getEVData({ data: currentState.operation.EvApplicationFormValue, page: thunkApi.page, selectedValue: thunkApi.per_page })))
                thunkApi.module_id == 2 && thunkApi.submodule_id == 21 && (thunkApi.is_dealDetails === true ? dispatch(getDealDetails(thunkApi.ticket_id)) : dispatch(getDemolition({ data: currentState.operation.DemolitionFormValue, page: thunkApi.page, selectedValue: thunkApi.per_page })))
                thunkApi.module_id == 2 && thunkApi.submodule_id == 12 && dispatch(surveyService.util.invalidateTags(["Survey"]))
                thunkApi.module_id === 3 && dispatch(logisticsService.util.invalidateTags(["Logistics"]))
                thunkApi.module_id === 8 &&  dispatch(financeService.util.invalidateTags(["Finance"]))
                thunkApi.module_id === 11 &&  dispatch(customerSupportService.util.invalidateTags(["CustomerSupport"]))
                thunkApi.module_id === 9 && thunkApi.submodule_id == 14 && dispatch(CPOService.util.invalidateTags(["CPO"]))
                thunkApi.module_id === 9 && thunkApi.submodule_id == 13 && dispatch(logisticsService.util.invalidateTags(["Stations"]))
                thunkApi.module_id === 9 && thunkApi.submodule_id == 15 && dispatch(HostService.util.invalidateTags(["Host"]))
                thunkApi.module_id === 9 && thunkApi.submodule_id == 16 && dispatch(logisticsService.util.invalidateTags(['Investor']))
                thunkApi.module_id === 7 && dispatch(getProjectsData({ data: currentState.Projects.ProjectsFormValue, page: thunkApi.page, selectedValue: thunkApi.per_page }))
                thunkApi.module_id === 10 && dispatch(techSupportService.util.invalidateTags(["TechSupport"]))
                thunkApi.module_id === 13 && (thunkApi.is_dealDetails === true ? dispatch(getNetworkReliabilityTicketDeatil(thunkApi.ticket_id)) : dispatch(getNetworkReliabilityData({ data: currentState.NetworkReliability.NetworkReliabilityFormValue, page: thunkApi.page, selectedValue: thunkApi.per_page })))
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });



export const getCommentMentions: any = createAsyncThunk(
    'CommentMentions', async (thunkApi: any, { getState, dispatch }) => {
        try {
            const currentState: any = getState()
            const response: any = await axiosInstance(`comments/comment_mentions`, {
                method: 'GET',
                params: {
                    ticket_id: thunkApi?.ticket_id,
                    submodule_id: thunkApi?.submodule_id || "",
                    module_id: thunkApi?.module_id,
                    "role_id": currentState?.verifyLogin?.loginData?.user_data?.role_id
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });


export const getChargerDeatils: any = createAsyncThunk(
    'getChargerDeatils', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`charger_details?ticket_id=${thunkApi?.ticket_id}&ticket_type=${thunkApi?.ticket_type}`, {
                method: 'GET',
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

    export const updateChargerDeatils: any = createAsyncThunk(
        'updateChargerDeatils', async (thunkApi: any, { getState, dispatch }) => {
            try{
                const response: any = await axiosInstance(`charger_details`, {
                    method: 'PUT',
                    data: JSON.stringify(thunkApi.data)
                })
                return response
            }
            catch(err){
                console.log(err);
            }
        });

export const getChargerMakeDeatils: any = createAsyncThunk(
    'getChargerMakeDeatils', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`make?ticket_id=${thunkApi?.ticket_id}&ticket_type=${thunkApi?.ticket_type}`, {
                method: 'GET',
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

    export const getAmenities: any = createAsyncThunk(
        'getAmenities', async (thunkApi: any) => {
            try {
                const response: any = await axiosInstance(`amenities`, {
                    method: 'GET',
                })
                return response.data;
            } catch (error: any) {
                return thunkApi.rejectWithValue({ error: error.message });
            }
        });

    // Executed by filter 
    export const getAssignedToFilter: any = createAsyncThunk(
        'getAssignedToFilter', async (thunkApi: any, { getState, dispatch }) => {
            try {
                const currentState: any = getState();
                const response: any = await axiosInstance(`get_assign_to`, {
                    method: 'GET',
                    params: {
                        "ticket_id": thunkApi.ticket_id ?? '',
                        "submodule_id": thunkApi.submodule_id ?? '',
                        "module_id": thunkApi.module_id ?? '',
                        "search_key": thunkApi.search_key ?? '',
                        "role_id": currentState?.verifyLogin?.loginData?.user_data?.role_id
                    }
                })
                return response.data;
            } catch (error: any) {
                return thunkApi.rejectWithValue({ error: error.message });
            }
    }); 

const commonSlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setCurrentModuleId: (state: any, payload: any) => {
            state.currentModuleId = payload?.payload
        }
    },
    extraReducers: (builder: any) => {
        return builder.addCase(getCategories.pending, (state: any) => {
            state.categoriesLoading = true
            state.categories = []
        }),
            builder.addCase(getCategories.fulfilled, (state: any, { payload }: any) => {
                state.categoriesLoading = false
                state.categories = payload
            }),
            builder.addCase(getCategories.rejected, (state: any) => {
                state.categoriesLoading = false
                state.categories = []
            }),
            builder.addCase(getStatuses.pending, (state: any) => {
                state.statusLoading = true
            }),
            builder.addCase(getStatuses.fulfilled, (state: any, { payload }: any) => {
                state.statusLoading = false
                state.status = payload
            }),
            builder.addCase(getStatuses.rejected, (state: any) => {
                state.statusLoading = false
            }),
            builder.addCase(getChargers.pending, (state: any) => {
                state.chargersLoading = true
            }),
            builder.addCase(getChargers.fulfilled, (state: any, { payload }: any) => {
                state.chargersLoading = false
                state.chargers = payload
            }),
            builder.addCase(getChargers.rejected, (state: any) => {
                state.chargersLoading = false
            }),
            builder.addCase(getDocumentTemplate.pending, (state: any) => {
                state.DocumentTemplateLoading = true
            }),
            builder.addCase(getDocumentTemplate.fulfilled, (state: any, { payload }: any) => {
                state.DocumentTemplateLoading = false
                state.DocumentTemplateData = payload
            }),
            builder.addCase(getDocumentTemplate.rejected, (state: any) => {
                state.DocumentTemplateLoading = false
                state.DocumentTemplateData = []
            }),
            builder.addCase(getOEM.pending, (state: any) => {
                state.oemLoading = true
            }),
            builder.addCase(getOEM.fulfilled, (state: any, { payload }: any) => {
                state.oemLoading = false
                state.oemData = payload?.data
            }),
            builder.addCase(getOEM.rejected, (state: any) => {
                state.oemLoading = false
            }),
            builder.addCase(getBrands.pending, (state: any) => {
                state.brandsLoading = true
            }),
            builder.addCase(getBrands.fulfilled, (state: any, { payload }: any) => {
                state.brandsLoading = false
                state.brandsData = payload?.data
            }),
            builder.addCase(getBrands.rejected, (state: any) => {
                state.brandsLoading = false
            }),
            builder.addCase(getCPO.pending, (state: any) => {
                state.CPOLoading = true
            }),
            builder.addCase(getCPO.fulfilled, (state: any, { payload }: any) => {
                state.CPOLoading = false
                state.CPOData = payload?.data?.message
            }),
            builder.addCase(getCPO.rejected, (state: any) => {
                state.CPOLoading = false
            }),
            builder.addCase(getTime.pending, (state: any) => {
                state.TimeLoading = true
            }),
            builder.addCase(getTime.fulfilled, (state: any, { payload }: any) => {
                state.TimeLoading = false
                state.TimeData = payload
            }),
            builder.addCase(getTime.rejected, (state: any) => {
                state.TimeLoading = false
            }),
            builder.addCase(getStates.pending, (state: any) => {
                state.statesLoading = true
            }),
            builder.addCase(getStates.fulfilled, (state: any, { payload }: any) => {
                state.statesLoading = false
                state.states = payload
            }),
            builder.addCase(getStates.rejected, (state: any) => {
                state.statesLoading = false
            }),
            builder.addCase(getCities.pending, (state: any) => {
                state.citiesLoading = true
            }),
            builder.addCase(getCities.fulfilled, (state: any, { payload }: any) => {
                state.citiesLoading = false
                state.cities = payload
            }),
            builder.addCase(getCities.rejected, (state: any) => {
                state.citiesLoading = false
            }),
            builder.addCase(getDestinationCities.pending, (state: any) => {
                state.destinationCitiesLoading = true
            }),
            builder.addCase(getDestinationCities.fulfilled, (state: any, { payload }: any) => {
                state.destinationCitiesLoading = false
                state.destinationCities = payload
            }),
            builder.addCase(getDestinationCities.rejected, (state: any) => {
                state.destinationCitiesLoading = false
            }),
            builder.addCase(getVendorList.pending, (state: any) => {
                state.vendorListLoading = true
            }),
            builder.addCase(getVendorList.fulfilled, (state: any, { payload }: any) => {
                state.vendorListLoading = false
                state.vendorList = payload
            }),
            builder.addCase(getVendorList.rejected, (state: any) => {
                state.vendorListLoading = false
            }),
            builder.addCase(getReasons.pending, (state: any) => {
                state.reasonLoading = true
            }),
            builder.addCase(getReasons.fulfilled, (state: any, { payload }: any) => {
                state.reasonLoading = false
                state.reasons = payload
            }),
            builder.addCase(getReasons.rejected, (state: any) => {
                state.reasonLoading = false
            }),
            builder.addCase(getStationDetail.pending, (state: any) => {
                state.StationLoading = true
            }),
            builder.addCase(getStationDetail.fulfilled, (state: any, { payload }: any) => {
                state.StationLoading = false
                state.StationData = payload
            }),
            builder.addCase(getStationDetail.rejected, (state: any) => {
                state.StationLoading = false
                state.StationData = []
            }),
            builder.addCase(getDealDoneByUsers.pending, (state: any) => {
                state.dealDoneByUsersLoading = true
            }),
            builder.addCase(getDealDoneByUsers.fulfilled, (state: any, { payload }: any) => {
                state.dealDoneByUsersLoading = false
                state.dealDoneData = payload
            }),
            builder.addCase(getDealDoneByUsers.rejected, (state: any) => {
                state.dealDoneByUsersLoading = false
                state.StationData = []
            }),
            builder.addCase(getCommentMentions.pending, (state: any) => {
                state.CommentMentionsLoading = true
                state.CommentMentions = []
            }),
            builder.addCase(getCommentMentions.fulfilled, (state: any, { payload }: any) => {
                state.CommentMentionsLoading = false
                state.CommentMentions = payload
            }),
            builder.addCase(getCommentMentions.rejected, (state: any) => {
                state.CommentMentionsLoading = false
                state.CommentMentions = []
            }),
            builder.addCase(getComments.pending, (state: any) => {
                state.getCommentsLoading = true
                state.comments = []
            }),
            builder.addCase(getComments.fulfilled, (state: any, { payload }: any) => {
                state.getCommentsLoading = false
                state.comments = payload
            }),
            builder.addCase(getComments.rejected, (state: any) => {
                state.getCommentsLoading = false
                state.comments = []
            }),
            builder.addCase(addComments.pending, (state: any) => {
                state.addCommentLoading = true
                state.addCommentSuccess = false
            }),
            builder.addCase(addComments.fulfilled, (state: any, { payload }: any) => {
                state.addCommentLoading = false
                state.addCommentSuccess = true
            }),
            builder.addCase(addComments.rejected, (state: any) => {
                state.addCommentLoading = false
                state.addCommentSuccess = false
            }),
            builder.addCase(editComments.pending, (state: any) => {
                state.editCommentLoading = true
                state.editCommentSuccess = false
            }),
            builder.addCase(editComments.fulfilled, (state: any, { payload }: any) => {
                state.editCommentLoading = false
                state.editCommentSuccess = true
            }),
            builder.addCase(editComments.rejected, (state: any) => {
                state.editCommentLoading = false
                state.editCommentSuccess = false
            }),
            builder.addCase(deleteComments.pending, (state: any) => {
                state.deleteCommentLoading = true
                state.deleteCommentSuccess = false
            }),
            builder.addCase(deleteComments.fulfilled, (state: any, { payload }: any) => {
                state.deleteCommentLoading = false
                state.deleteCommentSuccess = true
            }),
            builder.addCase(deleteComments.rejected, (state: any) => {
                state.deleteCommentLoading = false
                state.deleteCommentSuccess = false
            }),
            builder.addCase(getAssignedToList.pending, (state: any) => {
                state.AssignedToLoading = true
            }),
            builder.addCase(getAssignedToList.fulfilled, (state: any, { payload }: any) => {
                state.AssignedToLoading = false
                state.AssignedToData = payload
            }),
            builder.addCase(getAssignedToList.rejected, (state: any) => {
                state.AssignedToLoading = false
                state.AssignedToData = []
            }),
            // 
            builder.addCase(getAssignedToFilter.pending, (state: any) => {
                state.AssignedToFilterLoading = true
            }),
            builder.addCase(getAssignedToFilter.fulfilled, (state: any, { payload }: any) => {
                state.AssignedToFilterLoading = false
                state.AssignedToFilterData = payload
            }),
            builder.addCase(getAssignedToFilter.rejected, (state: any) => {
                state.AssignedToFilterLoading = false
                state.AssignedToFilterData = []
            }),
            // 
            builder.addCase(editAssignedToList.pending, (state: any) => {
                state.AssignedToEditLoading = true
            }),
            builder.addCase(editAssignedToList.fulfilled, (state: any, { payload }: any) => {
                state.AssignedToEditLoading = false
            }),
            builder.addCase(editAssignedToList.rejected, (state: any) => {
                state.AssignedToEditLoading = false
            }),
            builder.addCase(getChargerDeatils.pending, (state: any) => {
                state.chargerDetailsLoading = true
            }),
            builder.addCase(getChargerDeatils.fulfilled, (state: any, { payload }: any) => {
                state.chargerDetails = payload?.data
                state.chargerDetailsLoading = false
            }),
            builder.addCase(getChargerDeatils.rejected, (state: any) => {
                state.chargerDetailsLoading = false
                state.chargerDetails = []
            }),
            builder.addCase(updateChargerDeatils.pending, (state: any) => {
                state.updateChargerDeatilsLoading = true
                state.updateChargerDeatilsSuccess = false
            }),
            builder.addCase(updateChargerDeatils.fulfilled, (state: any, { payload }: any) => {
                state.updateChargerDeatilsLoading = false
                state.updateChargerDeatilsSuccess = true
            }),
            builder.addCase(updateChargerDeatils.rejected, (state: any) => {
                state.updateChargerDeatilsLoading = false
                state.updateChargerDeatilsSuccess = false
            }),
            builder.addCase(getChargerMakeDeatils.fulfilled, (state: any, { payload }: any) => {
                state.makeData = payload?.data
            }),
            builder.addCase(getAmenities.fulfilled, (state: any, { payload }: any) => {
                state.amenities = payload
            })
    },
})

export const { setCurrentModuleId } = commonSlice.actions

export const getAllCategories = (state: any) => state.common.categories;
export const getAllStatuses = (state: any) => state.common.status;
export const getAllchargers = (state: any) => state.common.chargers;
export const getAllStates = (state: any) => state.common.states;
export const getAllDestinationStates = (state: any) => state.common.states;
export const getAllCities = (state: any) => state.common.cities;
export const getAllDestinationCities = (state: any) => state.common.destinationCities;
export const getAllVendors = (state: any) => state.common.vendorList;
export const getVendorListLoading = (state: any) => state.common.vendorListLoading;
export const getDealDone = (state: any) => state.common.dealDoneData;

export default commonSlice.reducer